import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const StyledImagePreview = styled.img`
  width: 600px;
  height: auto;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const StyledIframePreview = styled.iframe`
  width: 600px;
  height: auto;
  cursor: pointer;
`

export const StyledPreviewModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledEvidenceImage = styled.img`
  border: 1px solid ${palette.neutral[100]};
  max-width: max-content;
  max-height: auto;
`

export const StyledPreviewModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`

export const StyledEvidenceIframe = styled.iframe`
  min-width: 100%;
  flex: 1 0 auto;
`

export const StyledPreviewModalIframe = styled.iframe`
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;
`

export const PreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`
