import React from 'react'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { useGooglePicker } from 'src/lib/Linkers/Linkers.hooks'
import { useLinkPolicyDocsConfig } from 'src/pages/Policies/Policies.hooks'
import { useGoogleDriveContext } from 'src/lib/Linkers/context/GoogleDriveContext'
import { FileSelectorText } from 'src/components/Reusable/Forms/FileSelector/FileSelector.styles'
import { ReactComponent as LinkIcon } from '../../../components/Icons/assets/permalink-icon.svg'
import { TypeButton } from '../FileType/TypeSelector.styles'
import { DocumentFormData, DocumentType } from './AddDocumentForm'
import { GoogleDriveIcon } from './AddDocumentForm.styles'

export const PolicyDocLinkTypeButton = ({
  setFormData,
  setType,
}: {
  setFormData: React.Dispatch<React.SetStateAction<DocumentFormData>>
  setType: React.Dispatch<React.SetStateAction<DocumentType>>
}): JSX.Element => {
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setFormData((state) => ({ ...state, mime: MIME_TYPE.TEXT_URI_LIST }))
    setType(DocumentType.LINK)
  }

  return (
    <TypeButton onClick={onClick}>
      <LinkIcon width="48px" height="48px" />
      <FileSelectorText>Paste Link</FileSelectorText>
    </TypeButton>
  )
}

export const PolicyDocGoogleDriveButton = ({
  policyId,
  hide,
  setGdriveError,
}: {
  policyId: string
  hide: () => void
  setGdriveError: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const openPicker = useGooglePicker()
  const linkPolicyDocsConfig = useLinkPolicyDocsConfig(policyId)
  const { setPickerConfig } = useGoogleDriveContext()

  return (
    <TypeButton
      onClick={async (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (!linkPolicyDocsConfig) return
        setPickerConfig(linkPolicyDocsConfig)
        const { isShown, invalidCredentials, exceptions } = await openPicker(
          linkPolicyDocsConfig,
        )
        if (isShown) {
          hide()
        } else if (exceptions) {
          setGdriveError(exceptions)
        } else if (invalidCredentials) {
          setGdriveError('Invalid Credentials.')
        }
      }}
    >
      <GoogleDriveIcon />
      <FileSelectorText>Google Drive</FileSelectorText>
    </TypeButton>
  )
}
