import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import UnknownSaaSPath from './unknown/saas_generic.svg'
import UnknownSoftwarePath from './unknown/software_generic.svg'
import CustomReceptorPath from './unknown/custom_receptor.svg'
import AkamaiPath from './general/akamai-icon.svg'
import AlpineLinuxPath from './alpine-linux/alpinelinux.svg'
import AmazonS3Path from './amazon/Arch_Amazon-S3-Standard_64.svg'
import AmazonDynamoDBPath from './amazon/Arch_Amazon-DynamoDB_16.svg'
import AmazonEC2Path from './amazon/Arch_Amazon-EC2_64.svg'
import AmazonEKSPath from './amazon/Arch_Amazon-EKS_512.png'
import AmazonEBSPath from './amazon/Arch_Amazon-Elastic-Block-Store_64.svg'
import AmazonRDSPath from './amazon/Arch_Amazon-RDS_64.svg'
import AmazonELBPath from './amazon/Arch_Elastic-Load-Balancing_64.svg'
import AmazonCloudFrontPath from './amazon/Arch_Amazon-CloudFront_64.svg'
import AmazonCloudFormationPath from './amazon/Arch_AWS-CloudFormation_64.svg'
import AmazonCloudTrailPath from './amazon/Arch_AWS-Cloud-Trail_64.svg'
import AmazonCloudWatchPath from './amazon/Arch_Amazon-Cloudwatch_2400.png'
import AmazonConfigPath from './amazon/Arch_AWS-Config_64.svg'
import AmazonACMPath from './amazon/Arch_AWS-Certificate-Manager_64.svg'
import AmazonECSPath from './amazon/amazon-ecs-icon.svg'
import AmazonGuardDutyPath from './amazon/Arch_Amazon-GuardDuty_32.svg'
import AmazonIAMPath from './amazon/aws-iam.svg'
import AmazonInspectorPath from './amazon/Arch_AWS-Inspector.svg'
import AmazonSecurityHubPath from './amazon/Arch_AWS-Security-Hub_16.svg'
import AmazonVPCPath from './amazon/amazon-vpc.svg'
import AmazonWAFPath from './amazon/Arch_AWS-WAF_64.svg'
import AmazonCodeCommitPath from './amazon/Arch_AWS-CodeCommit_64.svg'
import AWSPath from './amazon/amazon-web-services-icon.svg'
import ApplePath from './apple/apple-logo.svg'
import AsanaPath from './general/asana_icon.svg'
import AssureHirePath from './assurehire/AssureHire.svg'
import Auth0Path from './auth0/auth0.svg'
import BitbucketPath from './atlassian/bitbucket.svg'
import ConfluencePath from './atlassian/Confluence.svg'
import JiraPath from './atlassian/Jira.svg'
import AlibabaCloudPath from './general/alibaba-cloud-icon.svg'
import BambooHRPath from './general/bambooHR.svg'
import BoxPath from './box/box.svg'
import CheckrPath from './general/checkr.svg'
import CircleCIPath from './circleci/Circleci-icon-logo.svg'
import CloudflarePath from './cloudflare/cf-logo-v.svg'
import CoreOSPath from './general/coreos-icon.svg'
import CurriculaPath from './curricula/curricula.svg'
import DatadogPath from './general/datadog-icon.svg'
import DeelPath from './deel/Deel.svg'
import DeepSourcePath from './deepsource/deepsource_logo.svg'
import DockerCRPath from './docker/Docker.svg'
import DocusignPath from './docusign/docusign.svg'
import DropboxPath from './general/dropbox-icon.svg'
import EmployeeNavigatorPath from './general/employeeNavigator.svg'
import FreshdeskPath from './general/freshdesk-icon.svg'
import GerritPath from './general/Gerrit_icon.svg'
import GitPath from './general/git.svg'
import GitHubMarkPath from './github/Octicons-mark-github.svg'
import GitHubDependabotPath from './github/github-dependabot.svg'
import GitLabPath from './general/GitLab_Logo.svg'
import GCPPath from './google/google-cloud-platform.svg'
import GCPAdminResourcePath from './google/google-cloud-admin.svg'
import GCPComputeEnginePath from './google/google-compute-engine.svg'
import GCPCloudStoragePath from './google/cloud_storage.svg'
import GCPCloudSQLPath from './google/cloud_sql.svg'
import GoogleDrivePath from './google/google-drive.svg'
import GooglePath from './google/google.svg'
import GoogleAPIPath from './google/google-api.svg'
import GreenhousePath from './general/GREENHOUSE_ICON_GREEN.svg'
import GustoPath from './gusto/gusto.svg'
import HubSpotPath from './hubspot/HubSpot.svg'
import IntercomPath from './general/intercom-icon.svg'
import JamfPath from './general/jamf-icon.svg'
import JenkinsPath from './general/jenkins-project-icon.svg'
import JiraServiceManagementPath from './jira-service-management/JiraServiceManagement.svg'
import LatticePath from './general/lattice_logo_nest_color.svg'
import LeverPath from './general/lever_rgb_mark_standard.svg'
import LinuxPath from './general/linux-icon.svg'
import LogglyPath from './general/loggly-icon.svg'
import LucidChartPath from './general/lucidchart.svg'
import LVFSPath from './general/LVFS.svg'
import MalwarebytesPath from './general/malwarebytes.svg'
import MicrosoftLogoPath from './microsoft/microsoft.svg'
import MicrosoftAzurePath from './general/microsoft-azure-icon.svg'
import MicrosoftOfficePath from './general/Microsoft_Office.svg'
import MiradorePath from './miradore/miradore.svg'
import MobileIronPath from './general/MobileIron.svg'
import MondayPath from './monday/Monday.svg'
import MVisionPath from './general/Mvision.svg'
import MySQLPath from './general/MySQL.svg'
import NetsuitePath from './general/netsuite.svg'
import NetworkTimeFoundationPath from './general/NetworkTimeFoundation.svg'
import NodeJSPath from './general/nodejs.svg'
import NotionPath from './notion/Notion.svg'
import OktaPath from './general/okta-icon.svg'
import OneLoginPath from './general/Onelogin_Mark_black_RGB.svg'
import PaloAltoNetworksPath from './general/paloalto_networks.svg'
import PaycomPath from './general/Paycom.svg'
import PingIdentityPath from './general/ping-identity-logo.svg'
import PrismaCloudPath from './general/Prisma_Cloud.svg'
import QualysPath from './general/qualys.svg'
import QuickBasePath from './general/quickbase-icon.svg'
import QuickbooksPath from './general/quickbooks-1.svg'
import RecruiteePath from './general/recruitee.svg'
import SalesforcePath from './salesforce/salesforce.svg'
import SAPPath from './SAP/sap-icon.svg'
import SAPLitmosPath from './SAP/SapLitmos.svg'
import SentinalOneSingularityPath from './general/SentinelOne_Singularity.svg'
import ServiceNowPath from './servicenow/ServiceNow.svg'
import SharePointPath from './sharepoint/SharePoint.svg'
import SkilljarPath from './general/skilljar.svg'
import SlackPath from './general/slack.svg'
import SnapcraftPath from './general/Snapcraft_icon_130160.svg'
import SplunkPath from './general/splunk.svg'
import SnykPath from './snyk/snyk.svg'
import SuccessFactorsPath from './general/successfactor.svg'
import TcpdumpPath from './general/tcpdump.svg'
import TerraformPath from './general/terraform-icon.svg'
import TriNetPath from './general/trinet-icon.svg'
import TrelloPath from './general/trello-icon.svg'
import TravisCIPath from './travisci/travis-ci-icon.svg'
import TrusteroPath from './trustero/trustero.svg'
import UbuntuPath from './general/ubuntu-icon.svg'
import VerkadaPath from './general/verkada.svg'
import WorkdayPath from './workday/Workday.svg'
import WorkspaceOnePath from './general/workspace-one.svg'
import ZendeskPath from './general/zendesk-icon.svg'
import ZincPath from './zinc/Zinc.svg'
import CertnPath from './certn/Certn.svg'
import NewRelicPath from './new-relic/NewRelic.svg'
import PagerDutyPath from './pagerduty/PagerDuty.svg'
import StatusPagePath from './statuspage/StatusPage.svg'
import HerokuPath from './heroku/Heroku.svg'
import PaychexPath from './paychex/Paychex.svg'
import LinearPath from './linear/Linear.svg'
import MongoDBAtlasPath from './mongodb-atlas/MongoDBAtlas.svg'
import HiBobPath from './hibob/HiBob.svg'
import RipplingPath from './rippling/Rippling.svg'
import JustworksPath from './justworks/Justworks.svg'
import KnowBe4Path from './knowbe4/KnowBe4.svg'
import TeamCityPath from './teamcity/TeamCity.svg'
import JumpCloudPath from './jumpcloud/JumpCloud.svg'
import VerificationXPath from './verificationx/VerificationX.svg'
import KekaPath from './keka/Keka.svg'
import SeismicPath from './seismic/Seismic.svg'
import SophosPath from './sophos/sophos.svg'
import NessusPath from './nessus/Nessus.svg'
import KandjiPath from './kandji/Kandji.svg'
import WizPath from './wiz/Wiz.svg'
import CrowdStrikePath from './crowdstrike/CrowdStrike.svg'
import AzureDevOpsBoardsPath from './azure-devops-boards/AzureDevOpsBoards.svg'
import AzureDevOpsReposPath from './azure-devops-repos/AzureDevOpsRepos.svg'
import AzureDevOpsPipelinesPath from './azure-devops-pipelines/AzureDevOpsPipelines.svg'
import RightHandPath from './right-hand/RightHand.svg'
import DayforcePath from './dayforce/Dayforce.svg'
import ADPPath from './adp/ADP.svg'
import PaycorPath from './paycor/paycor.svg'
import OpsGeniePath from './atlassian/opsgenie.svg'
import SemgrepPath from './semgrep/semgrep.svg'
import ClickUpPath from './clickup/ClickUp.svg'
import AnecdotesPath from './anecdotes/anecdotes.svg'
export function UnknownSaaS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={UnknownSaaSPath}
      width={width}
      height={height}
      alt={'UnknownSaaS'}
      {...rest}
    />
  )
}
export function UnknownSoftware({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={UnknownSoftwarePath}
      width={width}
      height={height}
      alt={'UnknownSoftware'}
      {...rest}
    />
  )
}

export function CustomReceptor({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CustomReceptorPath}
      width={width}
      height={height}
      alt={'CustomReceptor'}
      {...rest}
    />
  )
}

export function AlpineLinux({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AlpineLinuxPath}
      width={width}
      height={height}
      alt={'Alpine Linux'}
      {...rest}
    />
  )
}
export function AmazonS3({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonS3Path}
      width={width}
      height={height}
      alt={'AmazonS3'}
      {...rest}
    />
  )
}
export function AmazonEC2({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonEC2Path}
      width={width}
      height={height}
      alt={'AmazonEC2'}
      {...rest}
    />
  )
}

export function AmazonEKS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonEKSPath}
      width={width}
      height={height}
      alt={'AmazonEKS'}
      {...rest}
    />
  )
}

export function AmazonEBS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonEBSPath}
      width={width}
      height={height}
      alt={'AmazonEBS'}
      {...rest}
    />
  )
}
export function AmazonRDS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonRDSPath}
      width={width}
      height={height}
      alt={'AmazonRDS'}
      {...rest}
    />
  )
}
export function AmazonELB({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonELBPath}
      width={width}
      height={height}
      alt={'AmazonELB'}
      {...rest}
    />
  )
}
export function AmazonCloudFront({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonCloudFrontPath}
      width={width}
      height={height}
      alt={'AmazonCloudFront'}
      {...rest}
    />
  )
}
export function AmazonCertificateManager({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonACMPath}
      width={width}
      height={height}
      alt={'Amazon Certificate Manager'}
      {...rest}
    />
  )
}
export function AmazonCloudFormation({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonCloudFormationPath}
      width={width}
      height={height}
      alt={'AmazonCloudFormation'}
      {...rest}
    />
  )
}
export function AmazonCloudWatch({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonCloudWatchPath}
      width={width}
      height={height}
      alt={'AmazonCloudWatch'}
      {...rest}
    />
  )
}
export function AmazonCloudTrail({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonCloudTrailPath}
      width={width}
      height={height}
      alt={'AmazonCloudTrail'}
      {...rest}
    />
  )
}
export function AmazonCodeCommit({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonCodeCommitPath}
      width={width}
      height={height}
      alt={'Amazon Code Commit'}
      {...rest}
    />
  )
}
export function AmazonConfig({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonConfigPath}
      width={width}
      height={height}
      alt={'AmazonConfig'}
      {...rest}
    />
  )
}
export function AmazonECS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonECSPath}
      width={width}
      height={height}
      alt={'AmazonECS'}
      {...rest}
    />
  )
}
export function AmazonDynamoDB({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonDynamoDBPath}
      width={width}
      height={height}
      alt={'AmazonDynamoDB'}
      {...rest}
    />
  )
}
export function AmazonSecurityHub({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonSecurityHubPath}
      width={width}
      height={height}
      alt={'AmazonSecurityHub'}
      {...rest}
    />
  )
}
export function AmazonGuardDuty({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonGuardDutyPath}
      width={width}
      height={height}
      alt={'Amazon Guard Duty'}
      {...rest}
    />
  )
}
export function AmazonIAM({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonIAMPath}
      width={width}
      height={height}
      alt={'AmazonIAM'}
      {...rest}
    />
  )
}

export function AmazonInspector({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonInspectorPath}
      width={width}
      height={height}
      alt={'AmazonInspector'}
      {...rest}
    />
  )
}

export function AmazonVPC({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonVPCPath}
      width={width}
      height={height}
      alt={'AmazonVPC'}
      {...rest}
    />
  )
}
export function AmazonWAF({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AmazonWAFPath}
      width={width}
      height={height}
      alt={'Amazon WAF'}
      {...rest}
    />
  )
}
export function Apple({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={ApplePath}
      width={width}
      height={height}
      alt="Apple-Logo"
      {...rest}
    />
  )
}

export function AWS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={AWSPath} width={width} height={height} alt={'AWS'} {...rest} />
  )
}
export function Asana({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AsanaPath}
      width={width}
      height={height}
      alt={'Asana'}
      {...rest}
    />
  )
}
export const AssureHire = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={AssureHirePath}
      width={width}
      height={height}
      alt={'AsaAssureHire'}
      {...rest}
    />
  )
}
export function Auth0({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={Auth0Path}
      width={width}
      height={height}
      alt={'Auth0'}
      {...rest}
    />
  )
}
export function Bitbucket({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={BitbucketPath}
      width={width}
      height={height}
      alt={'Bitbucket'}
      {...rest}
    />
  )
}
export function Confluence({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={ConfluencePath}
      width={width}
      height={height}
      alt={'Confluence'}
      {...rest}
    />
  )
}
export function Jira({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={JiraPath} width={width} height={height} alt={'Jira'} {...rest} />
  )
}
export function AlibabaCloud({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AlibabaCloudPath}
      width={width}
      height={height}
      alt={'AlibabaCloud'}
      {...rest}
    />
  )
}
export function Akamai({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AkamaiPath}
      width={width}
      height={height}
      alt={'Akamai'}
      {...rest}
    />
  )
}
export function BambooHR({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={BambooHRPath}
      width={width}
      height={height}
      alt={'BambooHR'}
      {...rest}
    />
  )
}
export function Box({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={BoxPath} width={width} height={height} alt={'Box'} {...rest} />
  )
}
export function Checkr({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CheckrPath}
      width={width}
      height={height}
      alt={'Checkr'}
      {...rest}
    />
  )
}
export function CircleCI({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CircleCIPath}
      width={width}
      height={height}
      alt={'CircleCI'}
      {...rest}
    />
  )
}
export function Cloudflare({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CloudflarePath}
      width={width}
      height={height}
      alt={'Cloudflare'}
      {...rest}
    />
  )
}
export function CoreOS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CoreOSPath}
      width={width}
      height={height}
      alt={'CoreOS'}
      {...rest}
    />
  )
}
export function Curricula({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={CurriculaPath}
      width={width}
      height={height}
      alt={'Curricula'}
      {...rest}
    />
  )
}
export function Datadog({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={DatadogPath}
      width={width}
      height={height}
      alt={'Datadog'}
      {...rest}
    />
  )
}
export const Deel = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={DeelPath} width={width} height={height} alt={'Deel'} {...rest} />
  )
}
export function DockerCR({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={DockerCRPath}
      width={width}
      height={height}
      alt={'DockerCR'}
      {...rest}
    />
  )
}
export function Dropbox({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={DropboxPath}
      width={width}
      height={height}
      alt={'Dropbox'}
      {...rest}
    />
  )
}
export function DocuSign({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={DocusignPath}
      width={width}
      height={height}
      alt={'Docusign'}
      {...rest}
    />
  )
}
export function EmployeeNavigator({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={EmployeeNavigatorPath}
      width={width}
      height={height}
      alt={'EmployeeNavigator'}
      {...rest}
    />
  )
}
export function Freshdesk({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={FreshdeskPath}
      width={width}
      height={height}
      alt={'Freshdesk'}
      {...rest}
    />
  )
}
export function Gerrit({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GerritPath}
      width={width}
      height={height}
      alt={'Gerrit'}
      {...rest}
    />
  )
}
export function Git({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={GitPath} width={width} height={height} alt={'Git'} {...rest} />
  )
}
export function GitHubMark({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GitHubMarkPath}
      width={width}
      height={height}
      alt={'GitHubMark'}
      {...rest}
    />
  )
}
export function GitHubDependabot({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GitHubDependabotPath}
      width={width}
      height={height}
      alt={'GitHubDependabot'}
      {...rest}
    />
  )
}
export function GitLab({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GitLabPath}
      width={width}
      height={height}
      alt={'GitLab'}
      {...rest}
    />
  )
}
export function GCP({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={GCPPath} width={width} height={height} alt={'GCP'} {...rest} />
  )
}
export function GCPAdminResource({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GCPAdminResourcePath}
      width={width}
      height={height}
      alt={'GCPAdminResource'}
      {...rest}
    />
  )
}

export function GCPComputeEngine({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GCPComputeEnginePath}
      width={width}
      height={height}
      alt={'GCPComputeEngine'}
      {...rest}
    />
  )
}

export function GoogleDrive({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GoogleDrivePath}
      width={width}
      height={height}
      alt={'Google Drive'}
      {...rest}
    />
  )
}

export function Google({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GooglePath}
      width={width}
      height={height}
      alt={'Google'}
      {...rest}
    />
  )
}
export function GoogleAPI({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GoogleAPIPath}
      width={width}
      height={height}
      alt={'GoogleAPI'}
      {...rest}
    />
  )
}
export function Greenhouse({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GreenhousePath}
      width={width}
      height={height}
      alt={'Greenhouse'}
      {...rest}
    />
  )
}
export function Gusto({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GustoPath}
      width={width}
      height={height}
      alt={'Gusto'}
      {...rest}
    />
  )
}
export const HubSpot = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={HubSpotPath}
      width={width}
      height={height}
      alt={'HubSpot'}
      {...rest}
    />
  )
}
export function Intercom({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={IntercomPath}
      width={width}
      height={height}
      alt={'Intercom'}
      {...rest}
    />
  )
}
export function Jamf({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={JamfPath} width={width} height={height} alt={'Jamf'} {...rest} />
  )
}
export function Jenkins({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={JenkinsPath}
      width={width}
      height={height}
      alt={'Jenkins'}
      {...rest}
    />
  )
}
export const JiraServiceManagement = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={JiraServiceManagementPath}
      width={width}
      height={height}
      alt={'Jira Service Management'}
      {...rest}
    />
  )
}
export function Lattice({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={LatticePath}
      width={width}
      height={height}
      alt={'Lattice'}
      {...rest}
    />
  )
}
export function Lever({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={LeverPath}
      width={width}
      height={height}
      alt={'Lever'}
      {...rest}
    />
  )
}
export function Linux({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={LinuxPath}
      width={width}
      height={height}
      alt={'Linux'}
      {...rest}
    />
  )
}
export function Loggly({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={LogglyPath}
      width={width}
      height={height}
      alt={'Loggly'}
      {...rest}
    />
  )
}
export function LucidChart({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={LucidChartPath}
      width={width}
      height={height}
      alt={'LucidChart'}
      {...rest}
    />
  )
}
export function LVFS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={LVFSPath} width={width} height={height} alt={'LVFS'} {...rest} />
  )
}
export function Malwarebytes({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MalwarebytesPath}
      width={width}
      height={height}
      alt={'Malwarebytes'}
      {...rest}
    />
  )
}
export function MicrosoftLogo({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MicrosoftLogoPath}
      width={width}
      height={height}
      alt="Microsoft-Logo"
      {...rest}
    />
  )
}
export function MicrosoftAzure({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MicrosoftAzurePath}
      width={width}
      height={height}
      alt={'MicrosoftAzure'}
      {...rest}
    />
  )
}
export function MicrosoftOffice({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MicrosoftOfficePath}
      width={width}
      height={height}
      alt={'MicrosoftOffice'}
      {...rest}
    />
  )
}
export function MobileIron({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MobileIronPath}
      width={width}
      height={height}
      alt={'MobileIron'}
      {...rest}
    />
  )
}
export const Monday = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={MondayPath}
      width={width}
      height={height}
      alt={'Monday.com'}
      {...rest}
    />
  )
}
export function MVision({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MVisionPath}
      width={width}
      height={height}
      alt={'MVision'}
      {...rest}
    />
  )
}
export function MySQL({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MySQLPath}
      width={width}
      height={height}
      alt={'MySQL'}
      {...rest}
    />
  )
}
export function NetSuite({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={NetsuitePath}
      width={width}
      height={height}
      alt={'NetSuite'}
      {...rest}
    />
  )
}
export function NetworkTimeFoundation({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={NetworkTimeFoundationPath}
      width={width}
      height={height}
      alt={'NetworkTimeFoundation'}
      {...rest}
    />
  )
}
export function NodeJS({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={NodeJSPath}
      width={width}
      height={height}
      alt={'NodeJS'}
      {...rest}
    />
  )
}
export const Notion = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={NotionPath}
      width={width}
      height={height}
      alt={'Notion'}
      {...rest}
    />
  )
}
export function Okta({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={OktaPath} width={width} height={height} alt={'Okta'} {...rest} />
  )
}
export function OneLogin({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={OneLoginPath}
      width={width}
      height={height}
      alt={'OneLogin'}
      {...rest}
    />
  )
}
export function PaloAltoNetworks({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={PaloAltoNetworksPath}
      width={width}
      height={height}
      alt={'PaloAltoNetworks'}
      {...rest}
    />
  )
}
export function Paycom({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={PaycomPath}
      width={width}
      height={height}
      alt={'Paycom'}
      {...rest}
    />
  )
}
export function PingIdentity({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={PingIdentityPath}
      width={width}
      height={height}
      alt={'PingIdentity'}
      {...rest}
    />
  )
}
export function PrismaCloud({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={PrismaCloudPath}
      width={width}
      height={height}
      alt={'PrismaCloud'}
      {...rest}
    />
  )
}
export function Qualys({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={QualysPath}
      width={width}
      height={height}
      alt={'Qualys'}
      {...rest}
    />
  )
}
export function QuickBase({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={QuickBasePath}
      width={width}
      height={height}
      alt={'QuickBase'}
      {...rest}
    />
  )
}
export function Quickbooks({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={QuickbooksPath}
      width={width}
      height={height}
      alt={'Quickbooks'}
      {...rest}
    />
  )
}
export function Recruitee({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={RecruiteePath}
      width={width}
      height={height}
      alt={'Recruitee'}
      {...rest}
    />
  )
}
export function Salesforce({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SalesforcePath}
      width={width}
      height={height}
      alt={'Salesforce'}
      {...rest}
    />
  )
}
export function SAP({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={SAPPath} width={width} height={height} alt={'SAP'} {...rest} />
  )
}
export function SAPLitmos({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SAPLitmosPath}
      width={width}
      height={height}
      alt={'SAPLitmos'}
      {...rest}
    />
  )
}
export function SentinalOneSingularity({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SentinalOneSingularityPath}
      width={width}
      height={height}
      alt={'SentinalOneSingularity'}
      {...rest}
    />
  )
}
export const ServiceNow = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={ServiceNowPath}
      width={width}
      height={height}
      alt={'ServiceNow'}
      {...rest}
    />
  )
}
export const SharePoint = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={SharePointPath}
      width={width}
      height={height}
      alt={'SharePoint'}
      {...rest}
    />
  )
}
export function Skilljar({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SkilljarPath}
      width={width}
      height={height}
      alt={'Skilljar'}
      {...rest}
    />
  )
}
export function Slack({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SlackPath}
      width={width}
      height={height}
      alt={'Slack'}
      {...rest}
    />
  )
}
export function Snapcraft({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SnapcraftPath}
      width={width}
      height={height}
      alt={'Snapcraft'}
      {...rest}
    />
  )
}
export function Splunk({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SplunkPath}
      width={width}
      height={height}
      alt={'Splunk'}
      {...rest}
    />
  )
}
export function Snyk({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img src={SnykPath} width={width} height={height} alt={'Snyk'} {...rest} />
  )
}
export function SuccessFactors({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SuccessFactorsPath}
      width={width}
      height={height}
      alt={'SuccessFactors'}
      {...rest}
    />
  )
}
export function Tcpdump({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TcpdumpPath}
      width={width}
      height={height}
      alt={'Tcpdump'}
      {...rest}
    />
  )
}
export function Terraform({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TerraformPath}
      width={width}
      height={height}
      alt={'Terraform'}
      {...rest}
    />
  )
}
export function TriNet({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TriNetPath}
      width={width}
      height={height}
      alt={'TriNet'}
      {...rest}
    />
  )
}
export function Trello({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TrelloPath}
      width={width}
      height={height}
      alt={'Trello'}
      {...rest}
    />
  )
}
export function TravisCI({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TravisCIPath}
      width={width}
      height={height}
      alt={'TravisCI'}
      {...rest}
    />
  )
}
export function Trustero({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={TrusteroPath}
      width={width}
      height={height}
      alt={'Trustero'}
      {...rest}
    />
  )
}
export function Ubuntu({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={UbuntuPath}
      width={width}
      height={height}
      alt={'Ubuntu'}
      {...rest}
    />
  )
}
export function Verkada({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={VerkadaPath}
      width={width}
      height={height}
      alt={'Verkada'}
      {...rest}
    />
  )
}
export function Workday({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={WorkdayPath}
      width={width}
      height={height}
      alt={'Workday'}
      {...rest}
    />
  )
}
export function WorkspaceOne({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={WorkspaceOnePath}
      width={width}
      height={height}
      alt={'WorkspaceOne'}
      {...rest}
    />
  )
}
export function Zendesk({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={ZendeskPath}
      width={width}
      height={height}
      alt={'Zendesk'}
      {...rest}
    />
  )
}

export function GCPCloudStorage({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GCPCloudStoragePath}
      width={width}
      height={height}
      alt={'GCPCloudStorage'}
      {...rest}
    />
  )
}
export function GCPCloudSQL({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={GCPCloudSQLPath}
      width={width}
      height={height}
      alt={'GCPCloudSQL'}
      {...rest}
    />
  )
}
export function Deepsource({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={DeepSourcePath}
      width={width}
      height={height}
      alt={'DeepSource'}
      {...rest}
    />
  )
}
export function Azure({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MicrosoftAzurePath}
      width={width}
      height={height}
      alt={'Microsoft Azure'}
      {...rest}
    />
  )
}
export function Miradore({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={MiradorePath}
      width={width}
      height={height}
      alt={'Miradore'}
      {...rest}
    />
  )
}
export const Zinc = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={ZincPath} width={width} height={height} alt={'Zinc'} {...rest} />
  )
}
export const Certn = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={CertnPath}
      width={width}
      height={height}
      alt={'Certn'}
      {...rest}
    />
  )
}
export const NewRelic = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={NewRelicPath}
      width={width}
      height={height}
      alt={'NewRelic'}
      {...rest}
    />
  )
}
export const PagerDuty = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={PagerDutyPath}
      width={width}
      height={height}
      alt={'PagerDuty'}
      {...rest}
    />
  )
}
export const StatusPage = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={StatusPagePath}
      width={width}
      height={height}
      alt={'StatusPage'}
      {...rest}
    />
  )
}
export const Heroku = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={HerokuPath}
      width={width}
      height={height}
      alt={'Heroku'}
      {...rest}
    />
  )
}
export const Paychex = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={PaychexPath}
      width={width}
      height={height}
      alt={'Paychex'}
      {...rest}
    />
  )
}
export const Linear = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={LinearPath}
      width={width}
      height={height}
      alt={'Linear'}
      {...rest}
    />
  )
}
export const MongoDBAtlas = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={MongoDBAtlasPath}
      width={width}
      height={height}
      alt={'MongoDBAtlas'}
      {...rest}
    />
  )
}
export const HiBob = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={HiBobPath}
      width={width}
      height={height}
      alt={'HiBob'}
      {...rest}
    />
  )
}
export const Rippling = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={RipplingPath}
      width={width}
      height={height}
      alt={'Rippling'}
      {...rest}
    />
  )
}
export const Justworks = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={JustworksPath}
      width={width}
      height={height}
      alt={'Justworks'}
      {...rest}
    />
  )
}
export const KnowBe4 = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={KnowBe4Path}
      width={width}
      height={height}
      alt={'KnowBe4'}
      {...rest}
    />
  )
}
export const TeamCity = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={TeamCityPath}
      width={width}
      height={height}
      alt={'TeamCity'}
      {...rest}
    />
  )
}
export const JumpCloud = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={JumpCloudPath}
      width={width}
      height={height}
      alt={'JumpCloud'}
      {...rest}
    />
  )
}
export const VerificationX = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={VerificationXPath}
      width={width}
      height={height}
      alt={'VerificationX'}
      {...rest}
    />
  )
}
export const Keka = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={KekaPath} width={width} height={height} alt={'Keka'} {...rest} />
  )
}
export const Seismic = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={SeismicPath}
      width={width}
      height={height}
      alt={'Seismic'}
      {...rest}
    />
  )
}
export const Nessus = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={NessusPath}
      width={width}
      height={height}
      alt={'Nessus'}
      {...rest}
    />
  )
}
export const Kandji = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={KandjiPath}
      width={width}
      height={height}
      alt={'Kandji'}
      {...rest}
    />
  )
}
export const Wiz = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={WizPath} width={width} height={height} alt={'Wiz'} {...rest} />
  )
}
export const CrowdStrike = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={CrowdStrikePath}
      width={width}
      height={height}
      alt={'CrowdStrike'}
      {...rest}
    />
  )
}
export const AzureDevOpsBoards = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={AzureDevOpsBoardsPath}
      width={width}
      height={height}
      alt={'AzureDevOpsBoards'}
      {...rest}
    />
  )
}
export const AzureDevOpsRepos = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={AzureDevOpsReposPath}
      width={width}
      height={height}
      alt={'AzureDevOpsRepos'}
      {...rest}
    />
  )
}
export const AzureDevOpsPipelines = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={AzureDevOpsPipelinesPath}
      width={width}
      height={height}
      alt={'AzureDevOpsPipelines'}
      {...rest}
    />
  )
}
export const RightHand = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={RightHandPath}
      width={width}
      height={height}
      alt={'RightHand'}
      {...rest}
    />
  )
}
export const Dayforce = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img
      src={DayforcePath}
      width={width}
      height={height}
      alt={'Dayforce'}
      {...rest}
    />
  )
}
export const ADP = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={ADPPath} width={width} height={height} alt={'ADP'} {...rest} />
  )
}
export const Paycor = ({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element => {
  return (
    <img src={PaycorPath} width={width} height={height} alt={'ADP'} {...rest} />
  )
}
export function Sophos({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SophosPath}
      width={width}
      height={height}
      alt={'Sophos'}
      {...rest}
    />
  )
}
export function OpsGenie({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={OpsGeniePath}
      width={width}
      height={height}
      alt={'OpsGenie'}
      {...rest}
    />
  )
}
export function Semgrep({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={SemgrepPath}
      width={width}
      height={height}
      alt={'Semgrep'}
      {...rest}
    />
  )
}
export function ClickUp({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={ClickUpPath}
      width={width}
      height={height}
      alt={'ClickUp'}
      {...rest}
    />
  )
}

export function Anecdotes({
  width,
  height,
  ...rest
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>): JSX.Element {
  return (
    <img
      src={AnecdotesPath}
      width={width}
      height={height}
      alt={'Anecdotes'}
      {...rest}
    />
  )
}
