import React, { useCallback, useEffect, useMemo, useState } from 'react'
import log from 'loglevel'
import { SWRResponse } from 'swr'
import isString from 'lodash/isString'
import { Excluded } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Evidence,
  Struct,
} from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import { Tab } from 'react-bootstrap'
import {
  useExclusions,
  useFetchDocumentBody,
} from 'src/components/async/document/useDocument'
import {
  getEvidenceColumns,
  getEvidenceRows,
  getEvidenceSources,
} from 'src/Utils/Evidence/evidence.helpers'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { openInNewTab } from 'src/Utils/globalHelpers'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { FlexRow } from 'src/components/Reusable/Flex'
import { getServiceTemplate } from 'src/xgenerated/service'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { SortNoneSvg, SortAscSvg, SortDescSvg } from '../../../Icons/Basic'
import { Markup } from '../../../Reusable/Text/Markup'
import { EvidenceTabIcons } from './TabIcons'
import {
  DiscoveriesToMarkdown,
  ExclusionsToMarkdown,
  formatEvidence,
  getNoEvidenceMessage,
} from './ViewEvidenceForm.utils'
import {
  CustomTabs,
  EvidenceDataGridContainer,
  StyledEvidenceP,
} from './ViewEvidenceForm.styles'

export const markupSectionProps = {
  m: '0 !important',
  p: 's',
  border: '1px solid',
  borderColor: 'border.neutral.light',
  textStyle: 'body.default',
}

interface AutomaticEvidenceBodyProps {
  documentId: string
  contentId: string
  mime: string
  exclusions?: Excluded[]
}

export const withAsync = <Data,>(
  asyncCall: SWRResponse<Data, Error>,
  onResult: (data: Data) => JSX.Element,
): JSX.Element => {
  if (asyncCall.error) {
    log.error('error in withAsync:', asyncCall.error.message)
    return <></>
  }
  if (!asyncCall.data) {
    return <>Loading...</>
  }
  return onResult(asyncCall.data)
}

export const MarkdownArea = ({
  title,
  body,
}: {
  title?: string
  body: string
}): JSX.Element => {
  const hasNewEvidence = IsGatekeeperEnabled(GatekeeperFlag.NEW_EVIDENCE_TABLE)
  return (
    <>
      {title && <StyledEvidenceP>{title}</StyledEvidenceP>}
      <Markup
        markdown={body}
        sectionProps={{
          ...markupSectionProps,
          justifyContent: 'flex-start',
          overflowY: 'auto',
          height: `${hasNewEvidence ? 'unset' : '240px'}`,
        }}
        isEvidence
      />
    </>
  )
}

export const ManualEvidenceBody = ({
  contentId,
  mime,
  documentTitle = 'Evidence',
}: {
  contentId: string
  mime: string
  documentTitle?: string
}): JSX.Element => {
  const [markdownBody, setMarkdownBody] = useState<string>('')
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)

  useEffect(() => {
    const getBody = async () => {
      try {
        const body = await fetchDocumentBody()
        if (!body || !isString(body)) {
          showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
          return
        }
        setMarkdownBody(body)
      } catch (err) {
        log.error('error when downloading evidence body', err)
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
      }
    }
    if (!markdownBody) {
      getBody()
    }
  })

  const isLink = mime === MIME_TYPE.TEXT_URI_LIST
  const loadingMessage = 'Loading...'

  return (
    <>
      <StyledEvidenceP>{documentTitle}</StyledEvidenceP>
      {isLink ? (
        <>
          {!markdownBody ? (
            <>{loadingMessage}</>
          ) : (
            <TextButton
              onClick={(e) => {
                e.preventDefault()
                openInNewTab(markdownBody.trim())
              }}
            >
              {markdownBody.trim()}
            </TextButton>
          )}
        </>
      ) : (
        <MarkdownArea body={markdownBody || loadingMessage} />
      )}
    </>
  )
}

export const EvidenceDataGrid = (props: DataGridProps): JSX.Element => (
  <DataGrid
    {...props}
    sx={{
      fontFamily: 'Poppins',
    }}
    getRowClassName={(params) =>
      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
    }
    initialState={{
      sorting: {
        sortModel: [
          {
            field: props.columns[0].field,
            sort: 'asc',
          },
        ],
      },
    }}
    components={{
      ColumnSortedAscendingIcon: SortAscSvg,
      ColumnSortedDescendingIcon: SortDescSvg,
      ColumnUnsortedIcon: SortNoneSvg,
    }}
  />
)

export const EvidenceTabTitle = ({
  title,
  icon,
}: {
  title: string
  icon: JSX.Element
}): JSX.Element => {
  const hasNewEvidence = IsGatekeeperEnabled(GatekeeperFlag.NEW_EVIDENCE_TABLE)
  return (
    <FlexRow gap={8}>
      {hasNewEvidence && title}
      {icon}
    </FlexRow>
  )
}

export const AutomaticEvidenceTable = ({
  evidence,
  fromShow = false,
}: {
  evidence: Evidence
  fromShow?: boolean
}): JSX.Element => {
  const evidenceStruct: Struct = evidence.getStruct() ?? new Struct()
  const noEvidence = evidenceStruct.getRowsList().length === 0
  const noEvidenceMessage = getNoEvidenceMessage(evidence)

  const evidenceRows = getEvidenceRows(evidenceStruct)
  const evidenceColumns = getEvidenceColumns(evidenceStruct)

  return noEvidence ? (
    <MarkdownArea title="Evidence" body={noEvidenceMessage} />
  ) : (
    <>
      {!fromShow && <StyledEvidenceP>Evidence from Receptor</StyledEvidenceP>}
      <EvidenceDataGridContainer>
        <EvidenceDataGrid rows={evidenceRows} columns={evidenceColumns} />
      </EvidenceDataGridContainer>
    </>
  )
}

export const AutomaticEvidenceBody = ({
  documentId,
  contentId,
  mime,
}: AutomaticEvidenceBodyProps): JSX.Element => {
  const { data: exclusionsData } = useExclusions(documentId)
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const [docBody, setDocBody] = useState<Uint8Array | undefined>(undefined)

  const showNewEvidenceTable = IsGatekeeperEnabled(
    GatekeeperFlag.NEW_EVIDENCE_TABLE,
  )

  useEffect(() => {
    const getBody = async () => {
      try {
        const body = await fetchDocumentBody()
        if (!body || !(body instanceof Uint8Array)) {
          showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
          return
        }
        setDocBody(body)
      } catch (err) {
        log.error('error when downloading evidence body', err)
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
      }
    }
    if (!docBody) {
      getBody()
    }
  }, [fetchDocumentBody, docBody])

  const getServiceName = useCallback(
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
    [],
  )

  const exclusionBody = useMemo(() => {
    const exclusions =
      exclusionsData?.getExclusions()?.getExclusionsList() || []
    if (!exclusions.length) return 'No exclusions'
    return ExclusionsToMarkdown(exclusions, getServiceName)
  }, [exclusionsData, getServiceName])

  if (!docBody) {
    return <MarkdownArea title="Evidence" body="Loading..." />
  }

  const evidence: Evidence = Evidence.deserializeBinary(docBody)
  const evidenceSources = getEvidenceSources(evidence)
  const sourcesMarkdown = DiscoveriesToMarkdown(evidenceSources)
  const formattedEvidence = formatEvidence({ evidence })

  return (
    <CustomTabs
      defaultActiveKey="included"
      variant="pills"
      id="uncontrolled-tab-example"
      $isNewEvidence={showNewEvidenceTable}
    >
      <Tab
        eventKey="included"
        title={
          <EvidenceTabTitle
            title="Included"
            icon={<EvidenceTabIcons.Included />}
          />
        }
      >
        {showNewEvidenceTable ? (
          <AutomaticEvidenceTable evidence={evidence} />
        ) : (
          <MarkdownArea title="Evidence" body={formattedEvidence} />
        )}
      </Tab>
      <Tab
        eventKey="excluded"
        title={
          <EvidenceTabTitle
            title="Excluded"
            icon={<EvidenceTabIcons.Excluded />}
          />
        }
      >
        <MarkdownArea title="Excluded from Scope" body={exclusionBody} />
      </Tab>
      <Tab
        eventKey="source"
        title={
          <EvidenceTabTitle
            title="API Response"
            icon={<EvidenceTabIcons.Raw />}
          />
        }
      >
        <MarkdownArea title="Evidence Source Data" body={sourcesMarkdown} />
      </Tab>
    </CustomTabs>
  )
}
