import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Document as DocumentMsg,
  DOCUMENT_TYPE,
  AddDocumentRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import isString from 'lodash/isString'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import utf8 from 'utf8'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import log from 'loglevel'
import { ThrobberContext } from 'src/Throbber/ThrobberContext'
import { Spinner } from 'src/Throbber'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { P } from 'src/components/Reusable/Text/Text.styles'
import palette from 'src/designSystem/variables/palette'
import { useGetPolicyDocumentsByModelId } from 'src/pages/Policies/Policies.hooks'
import { DerivedTextInput } from '../../Reusable/Inputs'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../ModalForm'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../adapter/grpcClient'
import { usePolicy } from '../../async/model'
import { isValidUrl } from '../../../Utils/validators'
import { DocumentTypeSelector } from './DocumentTypeSelector'
import { DocumentFile } from './DocumentFile'
import { DocumentLink } from './DocumentLink'
import { DocumentDetailsContainer, Label } from './AddDocumentForm.styles'

export type DocumentFormData = {
  mime: string
  caption: string
  body: string | File
}

export enum DocumentType {
  NULL = 'null',
  LINK = 'link',
  FILE = 'file',
}

export const AddDocumentQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  POLICY_ID: 'policyId',
}

type Props = {
  policyId: string
  openOnDrag?: boolean
}

export const AddDocumentForm = ({
  policyId,
  openOnDrag = false,
}: Props): JSX.Element => {
  const policy = usePolicy(policyId)?.data // Tests are complaining about destructuring here
  const { mutate: mutatePolicyDocs } = useGetPolicyDocumentsByModelId(
    policy?.getModelId(),
    !!policy?.getModelId(),
  )
  const attachmentClient = useAuthorizedGrpcClientWithContentUpdate(
    AttachmentPromiseClient,
  )
  const [formData, formDataSet] = useState<DocumentFormData>({
    mime: '',
    caption: '',
    body: '',
  })
  const [type, typeSet] = useState<DocumentType>(DocumentType.NULL)
  const [isValidLink, setIsValidLink] = useState<boolean>(true)
  const { setThrobberState } = useContext(ThrobberContext)
  const hasSubmitted = useRef<boolean>(false)

  useEffect(() => {
    if (hasSubmitted.current && isString(formData.body)) {
      setIsValidLink(isValidUrl(formData.body))
    }
  }, [formData.body, isValidLink])

  const show = useIsShowModal(ModalFormId.ADD_DOCUMENT)
  const hide = useHideModal({
    onHide: () => {
      formDataSet({
        mime: '',
        caption: '',
        body: '',
      })
      typeSet(DocumentType.NULL)
      hasSubmitted.current = false
      setIsValidLink(true)
    },
    modalId: ModalFormId.ADD_DOCUMENT,
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      // [AP-5006] TODO: Improve error handling
      try {
        e.preventDefault()
        if (!policy) {
          return
        }
        // Trigger URL link validation only after Submit
        if (type === DocumentType.LINK) {
          const isValid: boolean =
            isString(formData.body) && isValidUrl(formData.body)
          hasSubmitted.current = true
          setIsValidLink(isValid)
          if (!isValid) {
            return
          }
        }
        hide()
        setThrobberState({
          isShown: true,
          Display: <Spinner color="primary" size="xl" />,
        })

        // Add evidence
        const { mime, caption, body } = formData
        const bodyBytes = new Uint8Array(
          body instanceof Blob
            ? // Convert blob to arrayBuffer (aka byte array) first
              await new Response(body).arrayBuffer()
            : // Convert string into utf8 encoded codes
              Array.from(utf8.encode(body.trim())).map((s) => s.charCodeAt(0)),
        )
        const documentMsg = new DocumentMsg()
          .setSubjectmodeltype(MODEL_TYPE.POLICY)
          .setSubjectmodelid(policy.getModelId())
          .setDoctype(DOCUMENT_TYPE.POLICYDOC)
          .setMime(mime)
          .setBody(bodyBytes)
          .setCaption(caption.trim())
          // since the user is not using one of our templates, we set isCustomized to true
          .setIsCustomized(new BoolValue().setValue(true))

        await attachmentClient.addDocument(
          new AddDocumentRequest().setDocument(documentMsg),
        )
        await mutatePolicyDocs()
        // Close state and close modal
      } catch (e) {
        log.error('error in add document form', e)
        showInfoToast(ToastPrompts.POLICY_DOC_UPLOAD_FAILED)
      } finally {
        setThrobberState({
          isShown: false,
          Display: <></>,
        })
      }
    },
    [
      policy,
      type,
      hide,
      setThrobberState,
      formData,
      attachmentClient,
      mutatePolicyDocs,
    ],
  )

  return !policy ? (
    <></>
  ) : (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.ADD_DOCUMENT}
      title="Add Document for Policy:"
      description={`"${policy.getName()}"`}
      openOnDrag={openOnDrag}
    >
      <form
        id={ModalFormId.ADD_DOCUMENT}
        onSubmit={onSubmit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <DerivedTextInput
          required
          label="Caption"
          name="caption"
          initVal={formData.caption}
          placeholder="Add Caption for Document"
          form={ModalFormId.ADD_DOCUMENT}
          setFormData={
            formDataSet as Dispatch<SetStateAction<{ [key: string]: string }>>
          }
        />
        <DocumentDetailsContainer>
          <Label>Document Details</Label>
          {type === DocumentType.LINK && (
            <P $color={palette.orange['900']} $isBold>
              Currently Audit Scan can only evaluate evidence that is stored in
              the platform. If you store your evidence somewhere else that you
              link to, audit scan will not be able to evaluate it.
            </P>
          )}
          <DocumentTypeSelector
            {...{
              setFormData: formDataSet,
              type,
              setType: typeSet,
              hide,
              policyId,
            }}
          />
          <DocumentFile {...{ formData, type }} />
          <DocumentLink
            {...{
              formData,
              setFormData: formDataSet,
              isValidLink,
              formId: ModalFormId.ADD_DOCUMENT,
              type,
            }}
          />
        </DocumentDetailsContainer>
      </form>
    </ModalForm>
  )
}
