import { MIME_TYPE } from 'src/Utils/globalEnums'

// Default accepted file formats (MIME types)
export const DEFAULT_ACCEPTED_MIME_TYPES: MIME_TYPE[] = [
  MIME_TYPE.IMAGE_PNG,
  MIME_TYPE.IMAGE_JPEG,
  MIME_TYPE.APPLICATION_RTF, // Added RTF MIME type
  MIME_TYPE.APPLICATION_PDF,
  MIME_TYPE.APPLICATION_WORD,
  MIME_TYPE.APPLICATION_DOCUMENT,
  MIME_TYPE.TEXT_CSV,
  MIME_TYPE.APPLICATION_SPREADSHEET,
  MIME_TYPE.APPLICATION_VND_MS_EXCEL,
  MIME_TYPE.APPLE_PAGES,
  MIME_TYPE.APPLE_PAGES_2,
  MIME_TYPE.APPLE_KEYNOTE,
  MIME_TYPE.APPLE_KEYNOTE_2,
  MIME_TYPE.APPLE_KEYNOTE,
  MIME_TYPE.APPLE_NUMBERS,
  MIME_TYPE.APPLE_NUMBERS_2,
]

export const MAX_UPLOAD_MB = 100
export const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1024 * 1024
